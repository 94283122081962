import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Seo from 'components/seo';
import Layout from 'components/layout';
import Preview from 'components/@blog/preview';

import './styles.scss';

const SingleBlogPost = ({ pageContext }) => {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const currentArticle = edges.find(
    (edge) => edge?.node?.frontmatter?.slug === pageContext.slug
  );

  const relatedArticles = edges
    .filter((edge) => !!edge?.node?.frontmatter?.slug)
    .filter((edge) => edge?.node?.frontmatter?.slug !== pageContext.slug);

  const { frontmatter, html } = currentArticle.node;
  const { author, authorImage, title, datePublished, featuredImage } = frontmatter;

  const date = new Date(datePublished);

  return (
    <Layout>
      <Seo title={title} isArticle />
      <article className="article">
        <h1 className="article_title">{title}</h1>
        <div className="article_info">
          <div className="article_author">
            <img alt="author" className="article_author_image" src={authorImage} />
          </div>
          <p className="text-gilmer-regular">{author}</p>
          <span className="article_separator text-gilmer-regular">•</span>
          <p className="article_date text-gilmer-regular">{date.toDateString()}</p>
        </div>
        <section>
          <img alt="featured" src={featuredImage} />
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </section>
      </article>
      <section className="related">
        <div className="related_container">
          <p className="related_title text-gilmer-bold">Related articles</p>
          {relatedArticles.slice(0, 2).map((article) => {
            const { frontmatter } = article.node;

            return <Preview key={frontmatter.slug} {...frontmatter} />;
          })}
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ArticleQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            slug
            author
            authorImage
            datePublished
            description
            featuredImage
            title
            tags
          }
          html
        }
      }
    }
  }
`;

export default SingleBlogPost;
